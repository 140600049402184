import React, { Suspense } from 'react';
import { Container, Box } from '@mui/material';
import Loading from './Loading';

const BlogsSection = React.memo(() => {
    return (
        <Suspense fallback={<Loading />}>
            <Container
                maxWidth={false}
                disableGutters
                sx={{
                    backgroundColor: '#f2f2fc',
                    width: '100%',
                    py: { xs: 2, md: 3 },
                }}
            >
                <Box 
                    sx={{ 
                        width: { xs: '95%', sm: '90%' },
                        mx: 'auto',
                        textAlign: 'center',
                        px: { xs: 2, sm: 3 },
                    }}
                >
                    <iframe 
                        src="https://smallprep.substack.com/embed" 
                        width="100%" 
                        height="320" 
                        style={{ border: 'none', overflow: 'hidden' }} 
                        title="Growth Guide"
                    ></iframe>

                    <iframe 
                        src="https://smallprep09.substack.com/embed" 
                        width="100%" 
                        height="320" 
                        style={{ border: 'none', overflow: 'hidden' }}
                        title="SmallPrep 09"
                    ></iframe>
                </Box>
            </Container>
        </Suspense>
    );
});

export default BlogsSection;
